import styled from "styled-components";
import background from './BF3.png'
export const ContainerLeft = styled.div`
  z-index: 2;
  //background: #fbfbfb;
  position: relative;
  @media (max-width: 992px) {
    height: 35vh;
  }
  .container-logo {
    z-index: 2;
    .logo-mais-top {
      margin-top: 35px;
    }
  }
  .tom-mais-top {
    margin-left: 43vw;
    width: 816px
    @media (max-width: 1500px) {
      width: 1000px;
      margin-left: 21vw;
    }
    @media (max-width: 992px) {
      display: none;
    }
    overflow-y: hidden;
  }
  .container-tomas {
    position: fixed;
    top: 3rem;
  }
`;
export const ContainerRight = styled.div`
  /* background: #989898; */
  background: var(--background-escuro);
  /* background:  url(${background}) no-repeat center center; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 6rem;
  height: 74vh;
  bottom: 0;
  position: fixed;
  z-index: 3;
  .width-car-primeiro {
    width: 100%;
  }
  @media (min-width: 992px) {
    margin-top: 0;
    height: 100%;
    width: 70%;
    background: none;
    .width-car-primeiro {
      width: 21rem;
    }
  }
`;
export const CardLogin = styled.div`
  //height:65vh;
  /* visibility: hidden; */
  //margin-top: 10rem;

  //   background: white;
  position: relative;
  @media (min-width: 992px) {
    width: 30rem;
    height: 35rem;
  }

  .titulo-card {
    margin-top: 53px;
    margin-bottom: 27px;
    font-size: 24px;
    font-weight: bold;
    @media (max-width: 992px) {
      margin-top: 8px;
    }
    @media (max-height: 731px) {
      margin-top: 8px;
      margin-bottom: 10px;
    }
  }
  .card-login {
    box-shadow: 0 0 1rem rgb(0 0 0 / 15%);
    border-radius: 0.5rem;
    @media (max-width: 992px) {
      height: 33rem;
      margin-top: -20%;
      width: 24rem;

      //     width: 345px;
      //     margin-left: 11px;
      //    height: 475px;
      //    z-index: 4;
    }
  }

  .checkbox-senha {
    font-size: 12px;
    margin-top: 6px;
    span {
      margin-left: 5px;
    }
  }
  button {
    height: 45px;
    margin-bottom: 26px;
  }
  .possui-cadastro {
    font-size: 12px;
    text-align: center;
    a {
      color: #989898;
    }
  }
  .label-input {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .info-telefone {
    font-size: 14px;
    color: #fff;
    font-weight: 100;
    @media (max-width: 992px) {
      font-size: 10px!important;
      margin-bottom: 31px;
    }
    @media (max-height: 731px) {
      p{
        font-size: 10px;
      }
      h5{
        font-size: 15px;
      }
    }
  }
  
  .info-telefone .strong{
    font-weight: 800;
  }
`;

export const Recaptcha = styled.div`
  width: 307px;
  height: 76px;
  border: 1px solid;
`;
export const ContainerLeftMobile = styled.div`
  z-index: 2;
  //background: #fbfbfb;
  overflow-x: hidden;
  @media (max-width: 992px) {
    //  height: 5rem;
  }
  .container-logo {
    overflow: hidden;
    height: 58vh;
    .tomas-top {
      margin-top: 8rem;
      
      @media (max-width: 992px) {
        margin-top: 2rem;
        height: 930px!important;
      }
    }
  }
  .logo-mais-top {
    width: 5rem;
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
`;
export const ContainerRightMobile = styled.div`
  /* background: #c4c4c4; */
  display: flex;
  justify-content: center;
  height: 42vh;
`;

export const BodyModal = styled.div`
  background-color: #58595b;
  height: 100vh;

  .info-telefone {
    font-size: 14px;
    color: #fff;
  }
  .body-card {
    @media (min-width: 992px) {
      width: 28rem;
    }
  }
  .icon-size {
    font-size: 4rem;
  }
`;

export const BackGround = styled.div`
  img {
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    @media (max-width: 992px) {
          height:67%
        }
  }
  .bg-desktop {

    .bg-mobile-natal{
    display:none
        @media (max-width: 992px) {
          height:550px
        }
    }
    @media (max-width: 992px) {
      padding-left: 8%;
      height: 62vh;
    }

    display: inline;
    position: absolute;
  }
    
`;
export const LoginMobileComponent = styled.div`
  display: none;
  @media (max-width: 992px) {
    display: block;
  }
  .background-login-mobile {
    height: 496px;
    margin-right: -148px;
    margin-top: 0px;
  }
  .tomas-top-mobile {
    margin-left: 281px;
    z-index: -4;
    margin-top: -443px;
  }
`;
export const modalErroMsg = styled.div`
  position: absolute;
  z-index: 10;
`;
export const TampaoStyle = styled.div`
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 992px) {
    .img-mobile {
      display: block;
    }
    .img-desk {
      display: none;
    }
  }
  @media (min-width: 992px) {
    .img-mobile {
      display: none;
    }
    .img-desk {
      display: block;
    }
  }
`;
