const images = {
    LoginImg: require('./img/login.png'),
    LoginTomasPequeno: require('./img/tom-natal-24.png'),
    LoginImgPrimeiroAcesso: require('./img/login-primeiro-acesso2.png'),
    BackgroundLogin: require('./img/DesktopLogin.png'),
    TomasSobreMaisTop: require('./img/tomas-page-sobre.jpg'),
    TomasSobreMaisTopMobile: require('./img/tomas-page-sobre.jpg'),
    ExluirAnexo: require('./img/excluir-anexo.png'),
    ImgAnexo: require('./img/img-anexo.png'),
    ImgTampao: require('./img/tampao.jpg'),
    ImgTampaoMobile: require('./img/tampao-mobile.jpg'),
    TomCadastroSelfieDocumento: require('./img/TomCadastroSelfieDocumento.png'),
    ArteAniversaio: require('./img/Modal-aniversario.jpg'),
    NovaLojaCadastroIcon: require('./img/nova-loja-icon-success.png'),
    TomasBlackFridayLogin: require('./img/tomas-black-login.png'),
    BlackFridayBackGround: require('./img/blackfriday-bg.png'),
    MoedasBackGroundBlackFriday: require('./img/moedaslogin-bf.png'),
    NavBackgroundBlackFriday: require('./img/navbar-blackfriday.png'),
    FooterBackgroundBlackFriday: require('./img/bg-footer-blackfriday.png'),
    BannerCampanhaHexa: require('./img/banner-principal-campanha-fogoes-copa_AVISO_v1.jpg'),
    CheckIconTask: require('./img/icon-task-checked.svg'),
    IconTaskAusente: require('./img/conquista-ausente.png'),
    SeloTask: require('./img/selo-tasks.png'),
    SeloPremio: require('./img/premio-icon.png'),
    //Tema Natal 
    // LoginImgNatal: require('./img/tomas-tema-natal.png'),
    // BackgroundLoginNatal: require('./img/background-login-natal.png'),
    // NavBackgroundNatal: require('./img/navbar-natal.png'),
    // FooterBackgroundNatal: require('./img/footer-bg-natal.png'),
    // NavBarEnfeiteNatal: require('./img/enfeite-nav-bar-natal.png'),
    // EnfeiteBolasNatal: require('./img/enfeite-tela-login.png'),
    // BgNatalMobileLogin: require('./img/bg-login-mobile-natal.png'),
    // EnfeiteLoginMobile: require('./img/enfeite-login-mobile-bolas.png'),
    // BgFooterNatal: require('./img/footer-bg-footer.png'),
    // BgNavNatalMobile: require('./img/bg-mobile-nav-natal.png'),
    // BannerCampanhaBlackTop: require('./img/banner-principal-campanha_black_friday-v01.png'),
    // BannerCampanhaDezembro10: require('./img/campanhaDezembro.jpg'),
    // BannerCampanhaDezembro: require('./img/CampanhaDezembro2.jpg'),
    //Tema Bf
    BackgroundBf: require('./img/BF3.png'),
    BackgroundNavBf:require('./img/bg-nav-bf.png'),
    BgFooterBf: require('./img/footer-bg-bf.png'),
    TomasBfMobile: require('./img/tomas-black-login.png'),
};
export default images;
